import config from './config.js';

// Função para gerar uma string aleatória
const generateRandomString = (length) => {
  console.log(`Generating random string of length: ${length}`);
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// Função para gerar hash SHA-256
const sha256 = async (plain) => {
  console.log('Generating SHA-256 hash...');
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
};

// Função para codificar base64url
const base64urlencode = (a) => {
  console.log('Encoding Base64URL...');
  return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

// Função para gerar o desafio PKCE a partir do verificador
const pkceChallengeFromVerifier = async (v) => {
  console.log('Generating PKCE challenge...');
  const hashed = await sha256(v);
  return base64urlencode(hashed);
};

// Função para iniciar o login direto com Autodesk
const initiateAutodeskLogin = async () => {
  console.log('Initiating Autodesk Login...');
  const codeVerifier = generateRandomString(128);
  localStorage.setItem('pkce_code_verifier', codeVerifier);
  const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);
  const state = generateRandomString(16);

  console.log('Generated Code Verifier:', codeVerifier);
  console.log('Generated Code Challenge:', codeChallenge);
  console.log('Generated State:', state);

  const response = await fetch(
    `${config.apiUrl}/oauth2/auth/url?code_challenge=${encodeURIComponent(
      codeChallenge
    )}&state=${encodeURIComponent(state)}`
  );
  const authUrl = await response.text();
  console.log('Redirect URL:', authUrl);
  window.location.href = authUrl;
};

// Torna a função acessível no escopo global
window.initiateAutodeskLogin = initiateAutodeskLogin;

// Funções relacionadas ao token e logout
export function checkAuthToken() {
  const token = localStorage.getItem('authToken');
  return !!token; // Retorna true se o token existir
}

// Função para realizar logout
export function handleLogout() {
  console.log('Logging out...');

  // Remover tokens do localStorage
  localStorage.removeItem('authToken');
  localStorage.removeItem('pkce_code_verifier');

  // URL de logout da Autodesk
  const autodeskLogoutURL =
    'https://accounts.autodesk.com/Authentication/LogOut';

  // Após o logout, redireciona para a tela de login da sua aplicação
  window.location.href = `${autodeskLogoutURL}?redirect_uri=${encodeURIComponent(
    window.location.origin + '/login.html'
  )}`;
  console.log('Redirecting to logout URL:', redirectUrl);
  window.location.href = redirectUrl;
}

// Inicializa o botão de logout com eventos
export function initializeLogout() {
  // Seleciona o ícone de logout
  const logoutIcon = document.getElementById('logout-icon-home');

  if (logoutIcon) {
    // Adicionar evento de clique para logout
    logoutIcon.addEventListener('click', function () {
      handleLogout();
    });

    console.log('Logout initialized');
  } else {
    console.error('Logout icon not found');
  }
}

// Inicializa verificações de autenticação e eventos
export function initializeAuthCheck() {
  const isAuthenticated = checkAuthToken();
  console.log('Is user authenticated?', isAuthenticated);

  const currentPath = window.location.pathname;
  const loginPagePath = '/login.html'; // Certifique-se de que o caminho corresponde ao da sua aplicação

  if (!isAuthenticated && currentPath !== loginPagePath) {
    console.warn('No valid auth token found. Redirecting to login...');
    window.location.href = loginPagePath; // Redireciona para a página de login
  }
}
